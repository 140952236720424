import { CacheProvider } from "@emotion/react";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from "@mui/material/Snackbar";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from "styled-components";
import log from "loglevel";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import React from "react";
import "@redsift/design-system/style/redsift.css";
import { QueryClient, QueryClientProvider } from "react-query";

import AppContext from "../components/AppContext";
import RedSiftLoadingIndicator from "../components/RedSiftLoadingIndicator";
import * as gtag from "../libs/analytics/gtag";
import createEmotionCache from "../libs/createEmotionCache";
import theme from "../libs/theme";
import "../styles/globals.css";

const DEFAULT_ALERT_HIDE_DURATION = 6000;
const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

// Disable DEBUG level in production
log.setLevel("INFO");
// log.error("*** _app: *** DEBUG log is on, switch off when deploying to production ***");

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// Create a query client
const queryClient = new QueryClient();

type AppAlert = {
  open: boolean;
  severity: AlertColor;
  message: string;
  autoHideDuration: number | null;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MyApp({ Component, pageProps }: AppProps) {
  const [alert, setAlert] = React.useState<AppAlert>({
    open: false,
    severity: "info",
    message: "",
    autoHideDuration: DEFAULT_ALERT_HIDE_DURATION,
  });

  const [loading, setLoading] = React.useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = React.useState(true);

  const closeAlert = React.useCallback(
    () =>
      setAlert({
        open: false,
        severity: "info",
        message: "",
        autoHideDuration: DEFAULT_ALERT_HIDE_DURATION,
      }),
    [setAlert]
  );

  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <Head>
        <title>Red Sift</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      {typeof window != "undefined" && !(window as any).mixpanel && (
        <Script
          id="mixpanel-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
            MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
            mixpanel.init("${MIXPANEL_TOKEN}");
            `,
          }}
        />
      )}
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <AppContext.Provider
            value={{
              setAlert: (
                message: string,
                severity: AlertColor,
                duration?: number
              ) =>
                setAlert({
                  open: true,
                  severity,
                  message,
                  autoHideDuration: duration
                    ? duration === -1
                      ? null
                      : duration
                    : DEFAULT_ALERT_HIDE_DURATION,
                }),
              setLoading: (loading: boolean) => setLoading(loading),
              setRightDrawerOpen: (open: boolean) => {
                mixpanel.track("Toggle app drawer", { open: open.toString() });
                setRightDrawerOpen(open);
              },
              rightDrawerOpen,
            }}
          >
            <QueryClientProvider client={queryClient}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <Component {...pageProps} show={!loading} />
              <RedSiftLoadingIndicator show={loading} />
              <Snackbar
                open={alert.open}
                autoHideDuration={alert.autoHideDuration}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={closeAlert}
                  severity={alert.severity}
                  sx={{ width: "100%" }}
                >
                  {alert.message}
                </Alert>
              </Snackbar>
            </QueryClientProvider>
          </AppContext.Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </CacheProvider>
  );
}
